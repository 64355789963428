import React from "react";
import FaceToFace from '../components/FaceToFace';
import SevenDaysBanner from '../components/SevenDaysBanner';
import DefaultHero from "../components/DefaultHero";
import PriceCalculationButton from "../components/PriceCalculator/PriceCalculationButton";
import Alarmanlagen from "../components/Alarmanlagen";
import Footer from '../components/Footer';
import OurTeam from "../components/OurTeam";
import { StaticQuery, graphql } from "gatsby";
import HowTo2 from "../components/HowTo2";
import Helmet from 'react-helmet';
import Navbar from "../components/navbar";
import PromoBar from "../components/PromoBar";
import QueryString from "../helper/QueryString";
import IntrusionAtlas from "../components/IntrusionAtlas";
import Testimonials from "../components/Testimonials";

export const HeroElement = () => {
    return (
        <div className="homepage">
            <h1 className="top">Alarmanlagen von der Nr. 1 für Sicherheit</h1>
            <h2 className="middle">Vertrauensvoll. Kompetent. Sicher.</h2>
            <PriceCalculationButton city='partner'/>
        </div>
    );
};

export default () => {
    return(
        <StaticQuery
        query={graphql`
        query PartnerQuery {
            site {
                siteMetadata {
                  title
                  image
                  image768
                  image1024
                  image1216
                  image1408
                }
            }
      }`}
        render={data => {
            const city = {
                slug: 'partner'
            };
            const image = 'default/alarmanlagen-von-der-nr-1-fuer-sicherheit-3.jpg';
            const contextData = {
                title: data.site.siteMetadata.title,
                image: '/img/hero/' + image,
                image768: '/img/hero/default/alarmanlagen-von-der-nr-1-fuer-sicherheit-mobile2.jpg',
                image1024: '/img/hero/' + image,
                image1216: '/img/hero/' + image,
                image1408: '/img/hero/' + image,
                showPriceCalculator: false,
                showHeroMobile: false,
                showBottom: true,
                showElement: <HeroElement/>,
                special: 'partner'
            };

            let query = new QueryString();
            query.setSpecial('partner');

            return (
                <React.Fragment>
                    <header className="header">
                        <Helmet>
                            <html className='has-navbar-fixed-top' />
                            <title>Jetzt Preis für Ihre Alarmanlage berechnen | MeinAlarm24</title>
                            <meta name="description" content="Berechnen Sie in wenigen Minuten den Preis für Ihre Alarmanlage. ✓ Kostenlos ✓ Sicher ✓ Festpreis " />
                            <meta name="keywords" content="" />
                            <meta property="og:type" content="article" />
                            <meta property="og:title" content='Jetzt Preis für Ihre Alarmanlage berechnen | MeinAlarm24' />
                            <meta property="og:description" content="Berechnen Sie in wenigen Minuten den Preis für Ihre Alarmanlage. ✓ Kostenlos ✓ Sicher ✓ Festpreis " />
                            <meta property="og:image" content={image} />
                        </Helmet>
                        <PromoBar context={contextData} />
                        <Navbar context={contextData} />
                    </header>
                    <div>
                        <DefaultHero context={contextData}/>
                        <HowTo2 />
                        <FaceToFace />
                        <Testimonials />
                        <OurTeam/>
                        <Alarmanlagen />
                        <IntrusionAtlas context={contextData}/>
                        <SevenDaysBanner />
                        <Footer />
                    </div>
                </React.Fragment>
            )}
    }
    />
    );
}
